import { createSlice } from '@reduxjs/toolkit';
// utils
import axiosAuth from '../../utils/axiosAuth';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false
};

const slice = createSlice({
  name: 'export',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { onToggleFollow, deleteHelb } = slice.actions;

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export function exportPdf(payload) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosAuth.post('export/exportPdf', payload, { responseType: 'blob' });

      // Create a Blob from the PDF Stream
      const file = new Blob([response.data], { type: 'application/pdf' });

      // Build a URL from the file
      const fileURL = URL.createObjectURL(file);

      // Open the URL on new Window
      window.open(fileURL);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function exportCsv(payload) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosAuth.post('export/exportCsv', payload);

      // Create a Blob from the PDF Stream
      const file = new Blob([response.data.myCsv]);
      // Build a URL from the file
      const url = window.URL.createObjectURL(file);

      // Open the URL on new Window
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${response.data.title}.csv`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function exportPrint() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosAuth.get('helb/fetch_all');
      console.log(response);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
