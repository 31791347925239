import { filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axiosAuth from '../../utils/axiosAuth';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  feeStudentList: []
};

const slice = createSlice({
  name: 'feeStudent',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // DELETE feeStudent
    deleteFeeStudent(state, action) {
      const deleteFeeStudent = filter(state.employeeList, (employee) => employee._id !== action.payload);
      state.feeStudentList = deleteFeeStudent;
    },

    // GET All feeStudent
    getFeeStudentListSuccess(state, action) {
      state.isLoading = false;
      state.feeStudentList = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { onToggleFollow, deleteFeeStudent } = slice.actions;

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export function getFeeStudentList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosAuth.get('feeCollectionDash/fetch_student');
      dispatch(slice.actions.getFeeStudentListSuccess(response.data.students));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteStudent(id, data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axiosAuth.put(`student/delete/${id}`, data);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editStudentInfo(id, data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axiosAuth.put(`student/edit/student/${id}`, data);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editGuardianInfo(id, data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axiosAuth.put(`student/edit/guardian/${id}`, data);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
