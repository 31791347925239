// import { filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axiosAuth from '../../utils/axiosAuth';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  paymentRequests: []
};

const slice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET All PAYMENT REQUESTS
    getPaymentRequestsSuccess(state, action) {
      state.isLoading = false;
      state.paymentRequests = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { onToggleFollow, deleteFeeStudent } = slice.actions;

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export function getPaymentRequests() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosAuth.get('paymentRequests/fetch_all');
      dispatch(slice.actions.getPaymentRequestsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function makePaymentRequest(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosAuth.post('paymentRequests/create', data);
      dispatch(slice.actions.getPaymentRequestsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updatePaymentRequests(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getPaymentRequestsSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
