import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts

import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import AccGuard from '../guards/AccGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
// import useAuth from '../hooks/useAuth';
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        // {
        //   path: 'register',
        //   element: (
        //     <GuestGuard>
        //       <Register />
        //     </GuestGuard>
        //   )
        // },
        { path: 'login-unprotected', element: <Login /> },
        // { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
        { path: 'update_password/:token', element: <UpdatePassword /> }
      ]
    },

    // Create Account Routes
    {
      path: 'create-account',
      element: (
        <AuthGuard>
          <ClientType />
        </AuthGuard>
      )
    },

    {
      path: 'create-account/individual',
      element: (
        <AuthGuard>
          <IndividualDetails />
        </AuthGuard>
      )
    },
    {
      path: 'create-account/company',
      element: (
        <AuthGuard>
          <CompanyDetails />
        </AuthGuard>
      )
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <AccGuard>
            <DashboardLayout />
          </AccGuard>
        </AuthGuard>
      ),
      children: [
        { element: <DashExpenses /> },
        { path: 'app', element: <GeneralApp /> },
        { path: 'ecommerce', element: <GeneralEcommerce /> },
        { path: 'home', element: <DashExpenses /> },
        // { path: 'analytics', element: <GeneralAnalytics /> },
        { path: 'banking', element: <GeneralBanking /> },
        { path: 'booking', element: <GeneralBooking /> },

        {
          path: 'payments',
          children: [
            { element: <Navigate to="/dashboard/payments/payment-requests" replace /> },
            { path: 'payment-requests', element: <PaymentRequests /> }
          ]
        },

        {
          path: 'costItems',
          children: [
            { element: <Navigate to="/dashboard/costItems/salaries" replace /> },
            { path: 'salaries', element: <Salaries /> },
            { path: 'wages', element: <Wages /> },
            { path: 'travel-and-subsistance', element: <TravelSubsistance /> },
            { path: 'trade-licenses', element: <TradeLicense /> },
            { path: 'telephone-and-postage', element: <TelephonePostage /> },
            { path: 'printing-and-stationery', element: <PrintingStationery /> },
            { path: 'motor-vehicle-running-expenses', element: <MvFuel /> },
            { path: 'exam-gifts', element: <ExamGifts /> },
            { path: 'depreciation', element: <Depreciation /> },
            { path: 'general-expenses', element: <GeneralExpenses /> },
            { path: 'electricity', element: <Electricity /> },
            { path: 'water', element: <Water /> },
            { path: 'exam-payments', element: <ExamPayments /> },
            { path: 'medical-expenses', element: <MedicalExpenses /> },
            { path: 'kitchen-expenses', element: <KitchenExpenses /> },
            { path: 'nssf', element: <Nssf /> },
            { path: 'training', element: <Training /> },
            { path: 'magazines-and-periodicals', element: <MagazinePeriodicals /> },
            { path: 'computer-repair', element: <ComputerRepair /> },
            { path: 'entertainment', element: <Entertainment /> },
            { path: 'insurance', element: <Insurance /> },
            { path: 'buildings-repairs', element: <BuildingsRepair /> },
            { path: 'motor-vehicle-repairs', element: <MvRepair /> },
            { path: 'audit-fees', element: <AuditFees /> },
            { path: 'land-rates', element: <LandRates /> },
            { path: 'bank-charges', element: <BankCharges /> },
            { path: 'interest', element: <Interest /> }
          ]
        },

        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace /> },
            { path: 'profile', element: <UserProfile /> },
            { path: 'cards', element: <UserCards /> },
            { path: 'list', element: <UserList /> },
            { path: 'new', element: <UserCreate /> },
            { path: ':name/edit', element: <UserCreate /> },
            { path: 'account', element: <UserAccount /> }
          ]
        },

        {
          path: 'paymentsUser',
          children: [
            { element: <Navigate to="/dashboard/paymentsUser/list" replace /> },
            { path: 'list', element: <PaymentsUser /> }
          ]
        }
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },

    {
      path: '/',
      element: (
        <AccGuard>
          <Navigate to="/dashboard" replace />
        </AccGuard>
      )
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
// const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
const UpdatePassword = Loadable(lazy(() => import('../pages/authentication/UpdatePassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/authentication/VerifyCode')));

// Create Account
const ClientType = Loadable(lazy(() => import('../pages/account-creation/ClientType')));
const CompanyDetails = Loadable(lazy(() => import('../pages/account-creation/CompanyDetails')));
const IndividualDetails = Loadable(lazy(() => import('../pages/account-creation/IndividualDetails')));

// Dashboard
const DashExpenses = Loadable(lazy(() => import('../pages/dashboard/DashExpenses')));

const PaymentRequests = Loadable(lazy(() => import('../pages/dashboard/Payments/PaymentRequests')));

const Salaries = Loadable(lazy(() => import('../pages/dashboard/CostItems/Salaries')));
const Wages = Loadable(lazy(() => import('../pages/dashboard/CostItems/Wages')));
const TravelSubsistance = Loadable(lazy(() => import('../pages/dashboard/CostItems/TravelSubsitance')));
const TradeLicense = Loadable(lazy(() => import('../pages/dashboard/CostItems/TradeLicense')));
const TelephonePostage = Loadable(lazy(() => import('../pages/dashboard/CostItems/TelephonePostage')));
const PrintingStationery = Loadable(lazy(() => import('../pages/dashboard/CostItems/PrintingStationery')));
const MvFuel = Loadable(lazy(() => import('../pages/dashboard/CostItems/MvFuel')));
const ExamGifts = Loadable(lazy(() => import('../pages/dashboard/CostItems/ExamGifts')));
const Depreciation = Loadable(lazy(() => import('../pages/dashboard/CostItems/Depreciation')));
const GeneralExpenses = Loadable(lazy(() => import('../pages/dashboard/CostItems/GeneralExpenses')));
const Electricity = Loadable(lazy(() => import('../pages/dashboard/CostItems/Electricity')));
const Water = Loadable(lazy(() => import('../pages/dashboard/CostItems/Water')));
const ExamPayments = Loadable(lazy(() => import('../pages/dashboard/CostItems/ExamPayments')));
const MedicalExpenses = Loadable(lazy(() => import('../pages/dashboard/CostItems/MedicalExpenses')));
const KitchenExpenses = Loadable(lazy(() => import('../pages/dashboard/CostItems/KitchenExpenses')));
const Nssf = Loadable(lazy(() => import('../pages/dashboard/CostItems/Nssf')));
const Training = Loadable(lazy(() => import('../pages/dashboard/CostItems/Training')));
const MagazinePeriodicals = Loadable(lazy(() => import('../pages/dashboard/CostItems/MagazinePeriodicals')));
const ComputerRepair = Loadable(lazy(() => import('../pages/dashboard/CostItems/ComputerRepair')));
const Entertainment = Loadable(lazy(() => import('../pages/dashboard/CostItems/Entertainment')));
const Insurance = Loadable(lazy(() => import('../pages/dashboard/CostItems/Insurance')));
const BuildingsRepair = Loadable(lazy(() => import('../pages/dashboard/CostItems/BuildingsRepair')));
const MvRepair = Loadable(lazy(() => import('../pages/dashboard/CostItems/MvRepair')));
const AuditFees = Loadable(lazy(() => import('../pages/dashboard/CostItems/AuditFees')));
const LandRates = Loadable(lazy(() => import('../pages/dashboard/CostItems/LandRates')));
const BankCharges = Loadable(lazy(() => import('../pages/dashboard/CostItems/BankCharges')));
const Interest = Loadable(lazy(() => import('../pages/dashboard/CostItems/Interest')));

const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const GeneralEcommerce = Loadable(lazy(() => import('../pages/dashboard/GeneralEcommerce')));
// const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));
const GeneralBanking = Loadable(lazy(() => import('../pages/dashboard/GeneralBanking')));
const GeneralBooking = Loadable(lazy(() => import('../pages/dashboard/GeneralBooking')));
// const EcommerceShop = Loadable(lazy(() => import('../pages/dashboard/EcommerceShop')));
// const EcommerceProductDetails = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductDetails')));
// const EcommerceProductList = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductList')));
// const EcommerceProductCreate = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductCreate')));
// const EcommerceCheckout = Loadable(lazy(() => import('../pages/dashboard/EcommerceCheckout')));
// const EcommerceInvoice = Loadable(lazy(() => import('../pages/dashboard/EcommerceInvoice')));
// const BlogPosts = Loadable(lazy(() => import('../pages/dashboard/BlogPosts')));
// const BlogPost = Loadable(lazy(() => import('../pages/dashboard/BlogPost')));
// const BlogNewPost = Loadable(lazy(() => import('../pages/dashboard/BlogNewPost')));
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));

// const EmployeeExit = Loadable(lazy(() => import('../pages/dashboard/EmployeeExit')));
// const EmployeeList = Loadable(lazy(() => import('../pages/dashboard/EmployeeList')));
// const EmployeeCreate = Loadable(lazy(() => import('../pages/dashboard/EmployeeCreate')));
// const EmployeeEdit = Loadable(lazy(() => import('../pages/dashboard/EmployeeEdit')));
// const EmployeeProfile = Loadable(lazy(() => import('../pages/dashboard/EmployeeProfile')));

// const PayrollList = Loadable(lazy(() => import('../pages/dashboard/PayrollList')));
// const PayrollPast = Loadable(lazy(() => import('../pages/dashboard/PayrollPast')));
// const PayrollPayslip = Loadable(lazy(() => import('../pages/dashboard/PayrollPayslip')));
// const PayrollP9 = Loadable(lazy(() => import('../pages/dashboard/PayrollP9')));
// const PayrollProfile = Loadable(lazy(() => import('../pages/dashboard/PayrollProfile')));

// const SalaryAdvanceList = Loadable(lazy(() => import('../pages/dashboard/SalaryAdvanceList')));
// const SalaryAdvanceProfile = Loadable(lazy(() => import('../pages/dashboard/SalaryAdvanceProfile')));

// const DeductionHelb = Loadable(lazy(() => import('../pages/dashboard/DeductionHelb')));
// const DeductionLoan = Loadable(lazy(() => import('../pages/dashboard/DeductionLoan')));

const PaymentsUser = Loadable(lazy(() => import('../pages/dashboard/PaymentsUser')));

// const ReportsNHIF = Loadable(lazy(() => import('../pages/dashboard/ReportsNhif')));
// const ReportsNSSF = Loadable(lazy(() => import('../pages/dashboard/ReportsNssf')));
// const ReportsP9 = Loadable(lazy(() => import('../pages/dashboard/ReportsP9')));
// const ReportsPAYE = Loadable(lazy(() => import('../pages/dashboard/ReportsPaye')));

// const FeeOverview = Loadable(lazy(() => import('../pages/dashboard/FeeOverview')));
// const FeePayments = Loadable(lazy(() => import('../pages/dashboard/FeePayments')));
// const FeeClasses = Loadable(lazy(() => import('../pages/dashboard/FeeClasses')));
// const FeeStructures = Loadable(lazy(() => import('../pages/dashboard/FeeStructures')));
// const FeeStructuresView = Loadable(lazy(() => import('../pages/dashboard/FeeStructuresView')));
// const FeeStructuresViewTerm = Loadable(lazy(() => import('../pages/dashboard/FeeStructuresViewTerm')));

// const FeeClassesProfile = Loadable(lazy(() => import('../pages/dashboard/FeeClassesProfile')));

// const FeeStudentsEdit = Loadable(lazy(() => import('../pages/dashboard/FeeStudentsEdit')));
// const FeeStudentsProfile = Loadable(lazy(() => import('../pages/dashboard/FeeStudentsProfile')));
// const FeeSettings = Loadable(lazy(() => import('../pages/dashboard/FeeSettings')));

// const Wallet = Loadable(lazy(() => import('../pages/dashboard/Wallet')));

// const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
// const Mail = Loadable(lazy(() => import('../pages/dashboard/Mail')));
// const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
// const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));
// Main

const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
