import DashboardIcon from '@mui/icons-material/Dashboard';
import GroupsIcon from '@mui/icons-material/Groups';
import PaidIcon from '@mui/icons-material/Paid';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import PrintIcon from '@mui/icons-material/Print';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import BoltIcon from '@mui/icons-material/Bolt';
import WaterIcon from '@mui/icons-material/Water';
import PaymentsIcon from '@mui/icons-material/Payments';
import MedicationIcon from '@mui/icons-material/Medication';
import LocalDiningIcon from '@mui/icons-material/LocalDining';
import FeedIcon from '@mui/icons-material/Feed';
import HandymanIcon from '@mui/icons-material/Handyman';
import DevicesIcon from '@mui/icons-material/Devices';
import CasinoIcon from '@mui/icons-material/Casino';
import ReceiptIcon from '@mui/icons-material/Receipt';
import TerrainIcon from '@mui/icons-material/Terrain';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import BusinessIcon from '@mui/icons-material/Business';
import ChairAltIcon from '@mui/icons-material/ChairAlt';
import ComputerIcon from '@mui/icons-material/Computer';
import AppsIcon from '@mui/icons-material/Apps';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components

// ----------------------------------------------------------------------

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'home',
    items: [
      {
        title: 'Dashboard',
        path: PATH_DASHBOARD.general.home,
        icon: <DashboardIcon />
      }
    ]
  },

  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'payments',
    items: [
      {
        title: 'Payment Requests',
        path: PATH_DASHBOARD.payments.paymentRequests,
        icon: <RequestQuoteIcon />
      }
    ]
  },

  // ADMINISTRATIVE EXPENSES
  // ----------------------------------------------------------------------
  {
    subheader: 'Admin Expenses',
    items: [
      { title: 'Salaries', path: PATH_DASHBOARD.costItems.salaries, icon: <GroupsIcon /> },
      { title: 'Wages', path: PATH_DASHBOARD.costItems.wages, icon: <PaidIcon /> },
      { title: 'Travel & Subsistance', path: PATH_DASHBOARD.costItems.travelSubsistance, icon: <AirportShuttleIcon /> },
      { title: 'Trade License', path: PATH_DASHBOARD.costItems.tradeLicense, icon: <ReceiptLongIcon /> },
      { title: 'Telephone & Postage', path: PATH_DASHBOARD.costItems.telephonePostage, icon: <LocalPhoneIcon /> },
      { title: 'Printing & Stationery', path: PATH_DASHBOARD.costItems.printingStationery, icon: <PrintIcon /> },
      { title: 'MV Running Expnese (Fuel)', path: PATH_DASHBOARD.costItems.mvFuel, icon: <LocalGasStationIcon /> },
      { title: 'Exam Gifts', path: PATH_DASHBOARD.costItems.examGifts, icon: <CardGiftcardIcon /> },
      { title: 'Depreciation', path: PATH_DASHBOARD.costItems.depreciation, icon: <TrendingDownIcon /> },
      { title: 'General Expenses', path: PATH_DASHBOARD.costItems.generalExpenses, icon: <AllInboxIcon /> },
      { title: 'Electricity', path: PATH_DASHBOARD.costItems.electricity, icon: <BoltIcon /> },
      { title: 'Water', path: PATH_DASHBOARD.costItems.water, icon: <WaterIcon /> },
      { title: 'Exam Payments', path: PATH_DASHBOARD.costItems.examPayments, icon: <PaymentsIcon /> },
      { title: 'Medical Expenses', path: PATH_DASHBOARD.costItems.medicalExpenses, icon: <MedicationIcon /> },
      { title: 'Kitchen Expenses & Meals', path: PATH_DASHBOARD.costItems.kitchenExpenses, icon: <LocalDiningIcon /> },
      { title: 'NSSF', path: PATH_DASHBOARD.costItems.nssf, icon: <PaidIcon /> },
      { title: 'Training (NITA)', path: PATH_DASHBOARD.costItems.training, icon: <GroupsIcon /> },
      { title: 'Magazines & Periodicals', path: PATH_DASHBOARD.costItems.magazinePeriodicals, icon: <FeedIcon /> },
      {
        title: 'Repair & Maintenance - Computer',
        path: PATH_DASHBOARD.costItems.repairComputer,
        icon: <DevicesIcon />
      },
      { title: 'Entertainment', path: PATH_DASHBOARD.costItems.entertainment, icon: <CasinoIcon /> },
      { title: 'Insurance', path: PATH_DASHBOARD.costItems.insurance, icon: <ReceiptIcon /> },
      {
        title: 'Repair & Maintenance - Buildings',
        path: PATH_DASHBOARD.costItems.repairBuildings,
        icon: <HandymanIcon />
      },
      { title: 'Repair & Maintenance - MV', path: PATH_DASHBOARD.costItems.repairMv, icon: <HandymanIcon /> },
      { title: 'Land Rates', path: PATH_DASHBOARD.costItems.landRates, icon: <TerrainIcon /> },
      { title: 'Audit Fees', path: PATH_DASHBOARD.costItems.auditFees, icon: <VerifiedUserIcon /> }
    ]
  },

  // FINANCIAL EXPENSES
  // ----------------------------------------------------------------------
  {
    subheader: 'Financial Expenses',
    items: [
      { title: 'Bank Charges', path: PATH_DASHBOARD.costItems.bankCharges, icon: <AccountBalanceIcon /> },
      { title: 'Interest', path: PATH_DASHBOARD.costItems.interest, icon: <TrendingUpIcon /> }
    ]
  },

  // FEE COLLECTION
  // ----------------------------------------------------------------------
  {
    subheader: 'Assets',
    items: [
      { title: 'Motor Vehicles', path: PATH_DASHBOARD.assets.motorVehicles, icon: <DirectionsBusIcon /> },
      { title: 'Buildings', path: PATH_DASHBOARD.assets.buildings, icon: <BusinessIcon /> },
      { title: 'Furniture', path: PATH_DASHBOARD.assets.furniture, icon: <ChairAltIcon /> },
      { title: 'Computer Hardware', path: PATH_DASHBOARD.assets.computerHardware, icon: <ComputerIcon /> },
      { title: 'Computer Software', path: PATH_DASHBOARD.assets.computerSoftware, icon: <AppsIcon /> }
    ]
  }

  // APP
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'account',
  //   items: [
  //     {
  //       title: 'My Wallet',
  //       icon: ICONS.billing,
  //       path: PATH_DASHBOARD.wallet.root,
  //       info: <Label color="error">2</Label>
  //     },
  //     {
  //       title: 'settings',
  //       icon: ICONS.settings,
  //       path: PATH_DASHBOARD.general.analytics
  //     }
  // {
  //   title: 'deposits',
  //   path: PATH_DASHBOARD.user.account,
  //   icon: ICONS.billing,
  //   info: <Label color="error">2</Label>
  // },
  // { title: 'settings', path: PATH_DASHBOARD.user.account, icon: ICONS.settings }
  // // { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
  // // {
  // //   title: 'kanban',
  // //   path: PATH_DASHBOARD.kanban,
  // //   icon: ICONS.kanban
  // }
  //   ]
  // }
];

export default sidebarConfig;
