// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_CREATE = '/create-account';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_CREATE = {
  root: ROOTS_CREATE,
  company: path(ROOTS_CREATE, '/company'),
  individial: path(ROOTS_CREATE, '/individual')
};

export const PATH_PAGE = {
  page404: '/404',
  page500: '/500',
  components: '/components'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    home: path(ROOTS_DASHBOARD, '/home'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking')
  },

  payments: {
    root: path(ROOTS_DASHBOARD, '/payments'),
    paymentRequests: path(ROOTS_DASHBOARD, '/payments/payment-requests')
  },

  costItems: {
    root: path(ROOTS_DASHBOARD, '/costItems'),
    salaries: path(ROOTS_DASHBOARD, '/costItems/salaries'),
    wages: path(ROOTS_DASHBOARD, '/costItems/wages'),
    travelSubsistance: path(ROOTS_DASHBOARD, '/costItems/travel-and-subsistance'),
    tradeLicense: path(ROOTS_DASHBOARD, '/costItems/trade-licenses'),
    telephonePostage: path(ROOTS_DASHBOARD, '/costItems/telephone-and-postage'),
    printingStationery: path(ROOTS_DASHBOARD, '/costItems/printing-and-stationery'),
    mvFuel: path(ROOTS_DASHBOARD, '/costItems/motor-vehicle-running-expenses'),
    examGifts: path(ROOTS_DASHBOARD, '/costItems/exam-gifts'),
    depreciation: path(ROOTS_DASHBOARD, '/costItems/depreciation'),
    generalExpenses: path(ROOTS_DASHBOARD, '/costItems/general-expenses'),
    electricity: path(ROOTS_DASHBOARD, '/costItems/electricity'),
    water: path(ROOTS_DASHBOARD, '/costItems/water'),
    examPayments: path(ROOTS_DASHBOARD, '/costItems/exam-payments'),
    medicalExpenses: path(ROOTS_DASHBOARD, '/costItems/medical-expenses'),
    kitchenExpenses: path(ROOTS_DASHBOARD, '/costItems/kitchen-expenses'),
    nssf: path(ROOTS_DASHBOARD, '/costItems/nssf'),
    training: path(ROOTS_DASHBOARD, '/costItems/training'),
    magazinePeriodicals: path(ROOTS_DASHBOARD, '/costItems/magazines-and-periodicals'),
    repairComputer: path(ROOTS_DASHBOARD, '/costItems/computer-repair'),
    entertainment: path(ROOTS_DASHBOARD, '/costItems/entertainment'),
    insurance: path(ROOTS_DASHBOARD, '/costItems/insurance'),
    repairBuildings: path(ROOTS_DASHBOARD, '/costItems/buildings-repairs'),
    repairMv: path(ROOTS_DASHBOARD, '/costItems/motor-vehicle-repairs'),
    auditFees: path(ROOTS_DASHBOARD, '/costItems/audit-fees'),
    landRates: path(ROOTS_DASHBOARD, '/costItems/land-rates'),
    bankCharges: path(ROOTS_DASHBOARD, '/costItems/bank-charges'),
    interest: path(ROOTS_DASHBOARD, '/costItems/interest')
  },

  assets: {
    root: path(ROOTS_DASHBOARD, '/assets'),
    motorVehicles: path(ROOTS_DASHBOARD, 'assets/motor-vehicles'),
    buildings: path(ROOTS_DASHBOARD, 'assets/buildings'),
    furniture: path(ROOTS_DASHBOARD, 'assets/furniture'),
    computerHardware: path(ROOTS_DASHBOARD, 'assets/computer-hardware'),
    computerSoftware: path(ROOTS_DASHBOARD, 'assets/computer-software')
  },

  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    account: path(ROOTS_DASHBOARD, '/user/account')
  }
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
